<template>
  <div>
    <div
      class="block-icon-reglement-fac mr-1"
      @click.prevent.stop="masterFiliales(item)"
      title="Master Filiales"
    >
      <font-awesome-icon icon="bars" />
    </div>
    <!--GESTION DES PSEUDOS-->
    <v-dialog
      v-model="modalMasterFiliale"
      persistent
      max-width="900px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-list"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">LISTE DES SOCIÉTÉS </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalMasterFiliales('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div v-if="loading" class="loading-block">
            <v-progress-circular
              class="ml-2"
              v-if="loading"
              indeterminate
              color="#704ad1"
            ></v-progress-circular>
          </div>
          <div v-else class="form-add">
            <v-simple-table class="table-master">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center dark-class">SOCIÉTÉS</th>
                    <th class="text-center dark-class">DATE DÉBUT</th>
                    <th class="text-center dark-class">
                      DATE FIN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in getSocieteOfMasterFiliale"
                    :key="index"
                  >
                    <td class="text-center">{{ item.name }}</td>
                    <td class="text-center">{{ item.date_debut }}</td>
                    <td class="text-center">{{ item.date_fin }}</td>
                  </tr>
                  <tr
                    v-if="
                      getSocieteOfMasterFiliale &&
                        getSocieteOfMasterFiliale.length == 0
                    "
                  >
                    <td colspan="3" class="text-center">
                      Il n'y a aucun enregistrement à afficher
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleModalMasterFiliales('hide')"
            color="#704ad1"
          >
            Fermer
          </v-btn>
          <div
            v-if="getErrorsSocieteMasterFiliale"
            class="error-block message-error-modal"
          >
            <ul
              v-if="Array.isArray(getErrorsSocieteMasterFiliale)"
              class="mb-0"
            >
              <li
                v-for="(e, index) in getErrorsSocieteMasterFiliale"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ getErrorsSocieteMasterFiliale }}
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'master-filiales',
  props: {
    item: { required: true }
  },
  data() {
    return {
      modalMasterFiliale: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters([
      'getSocieteOfMasterFiliale',
      'getErrorsSocieteMasterFiliale'
    ])
  },
  methods: {
    ...mapActions(['getAllSocieteOfMasterFiliales']),
    masterFiliales(item) {
      this.getAllSocieteOfMasterFiliales(item.id);
      this.handleModalMasterFiliales('show');
    },
    handleModalMasterFiliales(action) {
      if (action == 'show') {
        this.modalMasterFiliale = true;
      } else {
        this.modalMasterFiliale = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.table-master {
  width: 70% !important;
  margin-left: 10% !important;
  table,
  td,
  tr,
  th {
    border: 1px solid #e0e0e0;
  }
}
.error-block {
  margin-left: 35%;
}
.loading-block {
  margin-left: 50%;
  margin-top: 1%;
}
</style>
